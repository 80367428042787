import { createSlice } from "@reduxjs/toolkit";

const productFeatures = createSlice({
    name: 'product',
    initialState: {
        data: [], 
        categories: [], 
        orderSpec: [],
        cart: {}
    },
    reducers: {
        setProduct: (state, action) => {
            state.data = action.payload;
        }, 
        setCategories: (state, action) => {
            state.categories = action.payload
        }, 
        setOrderSpec: (state, action) => {
            state.orderSpec = action.payload
        }, 
        removeProducts: (state) => {
            state.products = {}
        }, 
        removeCategories: (state) => {
            state.categories = {};
        }, 
        addToCart: (state, action) => {
            state.cart = [...state.cart, action.payload];
        }
    }
});

export const { setProduct, setCategories, removeProducts, removeCategories, addToCart, setOrderSpec } = productFeatures.actions;
export default productFeatures.reducer;