import { createSlice } from "@reduxjs/toolkit";

const subscriptionFeature = createSlice({
    name: 'subscription',
    initialState: {
        data: {}
    },
    reducers: {
        setSubscription: (state, action) => {
            state.data = action.payload;
        }
    }
});

export const { setSubscription } = subscriptionFeature.actions;
export default subscriptionFeature.reducer;