import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import thunk from "redux-thunk";

// all reducers
import authReducer from './features/authHandler.feature';
import subscriptionReducer from "./features/subscription.feature";
import productReducer from "./features/products.feature";
import cartReducer from "./features/cart.feature";

const reducers = combineReducers({
  auth: authReducer,
  subscriptions: subscriptionReducer, 
  products: productReducer , 
  cart: cartReducer
});
const persistConfig = {
  key: 'funfood',
  storage,
  whitelist: ['auth', 'subscriptions','cart'],
  blacklist: ['products']
};
const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});