import { createSlice } from "@reduxjs/toolkit";

const cartFeatures = createSlice({
    name: 'cart',
    initialState: {
        items: [], 
        totalPrice: 0
    },
    reducers: {
        setCartItems: (state, action) => {
            let ID = action.payload._id;

            let existingItem = state?.items?.filter(item => item._id == ID);
            
            if(Object.keys(existingItem).length > 0){                
                state.items.map((item, index) => {
                    if(item._id === ID){
                        item.quantity++;
                        item.totalItemPrice = Number(item.totalItemPrice) + Number(item.product_price);
                    };
                })
            } else {
                state.items = [...state.items, action.payload];
            }            

        }, 
        updateCartItem: (state, action) => {

            let ID = action.payload.d._id;
            let existingItem = state.items.filter(item => item._id == ID);

            if(Object.keys(existingItem).length > 0){
                state.items.map((item, index) => {
                    if(item._id === ID){
                        
                        if(item.quantity >= 1){
                            item.quantity += Number(action.payload.num);
                            item.totalItemPrice = Number(item.totalItemPrice) + action.payload.price; 
                            if(item.quantity < 1){
                                item.totalItemPrice = 0; 
                                state.items.splice(index, 1);
                            }
                        }
                    }
                })
            }
        },
        emptyCart: (state) => {
            state.items = [];
            state.totalPrice = 0;
        }, 
        removeSingleCartItem: (state, action) => {
            let index = state.items.findIndex(itm => itm._id == action.payload._id);
            if(index != -1){
                state.items.splice(index, 1); 
            }
            
            // let index = state.items.indexOf(action.payload);
            // state.items.splice(index, 1);
        }, 
        setCartTotal: (state, action) => {
            state.totalPrice = action.payload;
        }
    }
});

export const { setCartItems, emptyCart, removeSingleCartItem, updateCartItem, setCartTotal } = cartFeatures.actions;
export default cartFeatures.reducer;