import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const authHandlerFeature = createSlice({
    name: 'auth',
    initialState: {
        data: {},
        token: "",
        isLogin: false,
        isPaid: false
    },
    reducers: {
        login: (state, action) => {
            // data store to localstorage and after redirect to homescreen
            // let userData = localStorage.getItem("data");            
            // userData !== null ? JSON.parse(userData) : "";            
            state.data = action.payload;
            state.isLogin = true;
        },
        updateProfile: (state, action) => {
            state.data = action.payload.data;
        },
        setLoginStatus: (state, action) => {
            state.isLogin = action.payload;
        },
        logout: (state) => {
            state.data = {};
            state.token = "";
            state.isLogin = false;
            state.isPaid = false;
            Cookies.remove('token')
            window.localStorage.removeItem('persist:funfood');
        }, 
        saveCheckoutUserDetail: (state, action) => {
            state.checkoutUser = action.payload
        },
        setIsPaid: (state, action) => {
            state.isPaid = action.payload;
        }
    }
});

export const { login, setLoginStatus, logout, updateProfile, saveCheckoutUserDetail, setIsPaid } = authHandlerFeature.actions;
export default authHandlerFeature.reducer;